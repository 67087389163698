export const version="0.1"
export const build = 104

export const project = {
    "dev":"Narravero Web Tracer DEV",
    "test":"Narravero Web Tracer TEST",
    "prod":"Narravero Web Tracer",
    "emulator":"Local EMULATOR"
}


